@import "./theme.sass"
@import "./fonts.sass"

@media (min-width: 767px) 
    .header
        display: flex
        justify-content: space-between
        height: 5vw
        img
            margin-left: 10vw
            margin-top: 1.5vw
            height: 40%
        div
            width: 60vw
            display: flex
            justify-content: space-between
            align-items: center
        a
            color: #000
            text-decoration: none
            font-size: 1.1vw
            font-family: "Poppin"
            &:hover
                cursor: pointer
                font-weight: bold
        button
            &:hover
                cursor: pointer
        .connection
            margin-left: 10vw
        .signin
            background-color: white
            border: #000 solid 1px
            color: #000
            font-size: 1vw
            width: 12vw
            height: 3vw
            border-radius: .5vw
            margin-right: 10vw
            &:hover
                cursor: pointer
                background-color: #ececec
@media (max-width: 767px)
    .header
        display: flex
        justify-content: space-between
        height: 20vw
        img
            margin-left: 5vw
            margin-top: 5vw
            height: 40%
        div
            width: 28vw
            display: flex
            justify-content: space-between
            align-items: center
            a
                display: none
            .connection
                display: block
                font-size: 3vw
                text-align: center
                background-color: $secondary
                width: 25vw
                padding: 2.5vw 0 2.5vw 0
                color: white
                border-radius: 3vw
            
        a   
            color: #000
            text-decoration: none
            font-size: 1.1vw
            font-family: "Poppin"
            &:hover
                cursor: pointer
                font-weight: bold
        button
            display: none
        .signin
            display: none
