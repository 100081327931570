@import "./theme.sass"
@import "./fonts.sass"

@media (min-width: 768px) 
    .footer
        display: flex
        height: 18vw
        background-color: $secondary
        align-items: center
        flex-direction: column
        .logo
            margin: 3vw auto 1.5vw auto
            width: 10vw
        .menu
            display: flex
            justify-content: space-between
            align-items: center
            font-family: "dmsans-bold"
            font-size: 1vw
            text-transform: uppercase
            color: #fff
            width: 28vw
            letter-spacing: 2px
        .break
            width: 80%
            height: 1px
            border: 1px solid rgba(216, 209, 209, 0.3)
            margin: 1.5vw auto
        .social
            display: flex
            justify-content: space-between
            width: 80vw
            color: #fff
            font-family: "dmsans"
            font-size: 0.8vw
        a
            text-decoration: none
            color: unset
            &:hover
                cursor: pointer
                color: #c6c6c6
        .social-icons
            display: flex
            justify-content: space-between
            width: 10vw
            img 
                width: 2vw

@media (max-width: 767px) 
    .footer
        display: flex
        height: 90vw
        background-color: $secondary
        align-items: center
        flex-direction: column
        .logo
            margin: 10vw auto 10vw auto
            width: 40vw
        .menu
            display: flex
            justify-content: space-between
            align-items: center
            font-family: "dmsans-bold"
            font-size: 3vw
            text-transform: uppercase
            color: #fff
            width: 90vw
            letter-spacing: 2px
        .break
            width: 100%
            height: .5vw
            background-color: rgba(216, 209, 209, 0.3)
            margin: 1.5vw auto
            margin-top: 5vw
        .social
            display: flex
            flex-direction: column
            justify-content: space-between
            width: 90vw
            color: #fff
            font-family: "dmsans"
            font-size: 3vw
            text-align: center
            margin-top: 5vw
        a
            text-decoration: none
            color: unset
            &:hover
                cursor: pointer
                color: #c6c6c6
        .social-icons
            margin: 10vw auto 0 auto
            display: flex
            justify-content: space-between
            width: 40vw

            img 
                width: 8vw