@import "./../fonts.sass"
@import "./../theme.sass"

.menuperso
    padding-top: 5vw
    background-color: #f7f7f7
    @media (min-width: 739px)
        
        .apy
            display: block
            text-align: center
            font-size: 1vw
            font-family: 'poppin'
            text-decoration: none
            color: #000
            margin: 2vw auto

        .menu_select
            position: fixed
            display: none
        .menu
            padding-top: 5vw
            background-color: #f8f8f8
        .head
            img
                display: block
                margin: 0 auto
                width: 15vw
                height: 15vw
                border-radius: 3vw
            h1
                text-align: center
                font-size: 3vw
                font-family: "ConcertOne"
                color: $secondary
            .select
                width: 30vw
                height: 5vw
                margin: 0 auto
                font-size: 2vw
                font-family: "Poppin"
        .content
            display: flex
            flex-direction: column
            margin-top: 3vw
            h1
                font-size: 3vw
                font-family: "ConcertOne"
                margin-left: 35vw
            .title
                width: 15vw
                height: 5vw
                margin-left: 34vw
            .menu_items
                margin: 1vw auto
                box-shadow: 0 42px 165px rgb(31 37 85 / 6%), 0 5.25905px 20.6606px rgb(224 226 241 / 3%)
                border-radius: 3vw
                width: 35vw
                min-height: 30vw
                transform-origin: 0 0
                transform: none
                &:not(span)
                    background-color: #fff
                img
                    display: block
                    width: 25vw
                    height: 25vw
                    margin: 3vw auto 0 auto
                    border-radius: 1vw
                h2
                    margin-left: 5vw
                    font-size: 2.5vw
                    margin-bottom: 1vw
                    margin-top: 1vw
                    font-family: "ConcertOne"
                h3
                    margin-left: 5vw
                    font-size: 1.5vw
                    margin-bottom: 1vw
                    margin-top: 1vw
                    color: $secondary
                    font-family: "Poppin"
                p
                    margin-left: 5vw
                    font-size: 1.5vw
                    margin-bottom: 3vw
                    margin-top: 1vw
                    width: 25vw
                    font-family: "Poppin"

    @media (max-width: 740px)
        .apy
            display: block
            text-align: center
            font-size: 3vw
            font-family: 'poppin'
            text-decoration: none
            color: #000
            margin: 5vw auto
        .visible
            display: block !important
        .menu_select
            display: none
            position: fixed
            width: 100vw
            top: 0
            font-size: 6vw
            font-family: "Poppin"
        .menu
            background-color: #f8f8f8
            padding-top: 5vw
        .head
            img
                display: block
                margin: 0 auto
                width: 25vw
                height: 25vw
                border-radius: 3vw
            h1
                text-align: center
                font-size: 10vw
                margin-top: 1vw
                margin-bottom: 5vw
                font-family: "ConcertOne"
                color: $secondary
            .select
                width: 60vw
                height: 10vw
                margin: 1vw auto
                font-size: 5vw
                font-family: "Poppin"
        .content
            display: flex
            flex-direction: column
            margin-top: 3vw
            h1
                font-size: 8vw
                font-family: "ConcertOne"
                margin-left: 5vw
            .title
                width: 40vw
                height: 20vw
                margin-left: 5vw
            .menu_items
                margin: 5vw auto
                box-shadow: 0 42px 165px rgb(31 37 85 / 6%), 0 5.25905px 20.6606px rgb(224 226 241 / 3%)
                border-radius: 3vw
                width: 90vw
                min-height: 80vw
                transform-origin: 0 0
                transform: none
                &:not(span)
                    background-color: #fff
                img
                    display: block
                    width: 60vw
                    height: 60vw
                    margin: 8vw auto 0 auto
                    border-radius: 1vw
                h2
                    margin-left: 5vw
                    font-size: 8vw
                    margin-bottom: 1vw
                    margin-top: 5vw
                    font-family: "ConcertOne"
                h3
                    margin-left: 5vw
                    font-size: 5vw
                    margin-bottom: 1vw
                    margin-top: 5vw
                    color: $secondary
                    font-family: "Poppin"
                p
                    margin-left: 5vw
                    font-size: 4vw
                    margin-bottom: 8vw
                    margin-top: 1vw
                    width: 80vw
                    font-family: "Poppin"



