@import "./../fonts.sass"
@import "./../theme.sass"

@media (min-width: 768px) 
    html, body 
        margin: 0
        padding: 0
        overflow-x: hidden
        width: 100vw

    .start
        display: flex
        justify-content: space-between
        width: 80vw
        margin: 3vw auto
        .container
            width: 35vw
            h2
                font-family: "dmsans-bold"
                font-size: 4vw
                line-height: 4.5vw
                font-weight: bold
                margin-bottom: 0.5vw
                color: $primary
            p
                font-family: "avenir"
                font-size: 1.26vw
                margin-bottom: 3vw
            div
                display: flex
                button
                    font-family: "avenir"
                    font-size: .8vw
                    font-weight: bold
                    background-color: $secondary
                    color: #fff
                    border: none
                    border-radius: 1vw
                    width: 15vw
                    height: 4vw
                    &:hover
                        cursor: pointer
                        background-color: $secondary-hover
                p
                    font-size: .8vw
                    align-items: center
                    margin: 1.3vw 0 0 2vw
        img
            width: 35vw
            height: 35vw
            margin: 5vw 0 0 0

    .Une-image-vaut-mille-mots
        position: relative
        display: flex
        justify-content: space-between
        img
            width: 80vw
            margin: -10vw auto 0 -1vw
            pointer-events: none
        .motif1
            position: absolute
            width: 20vw
            right: 0
            bottom: -10vw
        .container
            position: absolute
            right: 12vw
            top: 15vw
            h4
                font-family: "avenir"
            h2
                font-family: "dmsans-bold"
                font-size: 2.5vw
                font-weight: bold
                margin: 0
                color: $primary
            p
                font-family: "avenir"
                font-size: 1.5vw
                margin-bottom: 3vw
                width: 30vw
            img
                margin-top: 1vw
                margin-left: 0
                width: 30vw

    .Une-Gestion-Plus-Efficace-Des-Commandes
        position: relative
        display: flex
        justify-content: space-between
        margin-top: 20vw
        .motif2
            position: absolute
            width: 20vw
            left: 0
            bottom: -20vw
        .container
            width: 40vw
            margin-left: 10vw
            margin-top: 8vw
            h2
                font-family: "dmsans-bold"
                font-size: 2.5vw
                font-weight: bold
                margin: 0
                color: $primary
            p
                font-family: "avenir"
                font-size: 1.3vw
                margin-bottom: 3vw
                width: 30vw
            .row
                p
                    position: relative
                    color: #1C1E52
                    font-family: "avenir heavy"
                    margin: 0.5vw 0 0 0
                    display: flex
                    align-items: center
                    &::before
                        content: ""
                        display: block
                        width: 2vw
                        height: 2vw
                        margin: -1vw 1vw 0 0
                        background-image: url("./../../images/like.png")
                        background-size: cover
                        background-repeat: no-repeat


        img
            width: 50vw

    .form
        position: relative
        background: #FFFFFF
        border: 1px solid #EAECF0
        box-shadow: 0px 24px 48px -12px rgba(17, 24, 39, 0.25)
        border-radius: 1vw
        width: 70vw
        margin: 15vw auto 10vw auto 
        img
            position: absolute
            width: 5vw
            height: 5vw
            top: -2.5vw
            right: -2.5vw
        h2
            font-family: "Helvetica"
            font-size: 2.5vw
            font-weight: bold
            margin: 4vw 0 0 5vw
            color: $primary
        p
            font-family: "avenir"
            margin: 1vw 0 0 5vw
            font-size: 1.3vw
        .submit
            font-family: 'Poppin'
            font-style: normal
            width: 7vw
            height: 2.5vw
            background-color: white
            border: .2vw #6697E6 solid
            border-radius: .5vw
            color: #6697E6
            font-size: .8vw
            margin: 3vw 0 5vw 5vw
            &:hover
                cursor: pointer
                background-color: #f1f1f1
        .form-group
            display: flex
            width: 60vw
            margin: 2vw auto 0 auto
            justify-content: space-between
            flex-wrap: wrap
            .field
                position: relative
                width: 28.5vw
                display: flex
                flex-direction: column
                font-family: "avenir heavy"
                p
                    font-weight: bold
                    font-size: .6vw
                    margin: 0
                    position: absolute
                    color: red
                    bottom: -.8vw
                    left: .5vw
                label
                    font-size: 1vw
                    font-weight: bold
                    margin: .8vw 0 .8vw 0
                input
                    font-family: arial
                    width: 100%
                    border: none
                    border-radius: 0.2vw
                    height: 2vw
                    border: 0.1vw solid #cccccc
                    padding: 0 0.5vw
                    font-size: .8vw
                .select__value-container
                    font-family: arial
                    font-weight: normal
                    font-size: .8vw
                .select__control
                    width: 29.6vw
                    height: 2.2vw
                    border: 0.1vw solid #cccccc
                .select__single-value
                    margin-top: -0.25vw
                .select__indicator-separator, .select__dropdown-indicator
                    margin-top: -0.1vw

                

@media (max-width: 767px) 
    html, body 
        margin: 0
        padding: 0
        overflow-x: hidden
        width: 100vw

    .start
        display: flex
        justify-content: space-between
        flex-direction: column
        width: 95vw
        margin: 3vw auto
        .container
            width: 90vw
            h2
                font-family: "helvetica"
                font-size: 6vw
                font-weight: bold
                margin-bottom: 0.5vw
                color: $primary
                text-align: center
            p
                font-family: "avenir"
                font-size: 3vw
                margin-bottom: 3vw
                text-align: center
            div
                display: flex
                flex-direction: column
                button
                    font-family: "avenir"
                    font-size: 4vw
                    font-weight: bold
                    background-color: $secondary
                    color: #fff
                    border: none
                    border-radius: 4vw
                    width: 60vw
                    height: 12vw
                    margin: 8vw auto 0 auto
                    &:hover
                        cursor: pointer
                        background-color: $secondary-hover
                p
                    font-size: 3vw
                    align-items: center
        img
            width: 90vw
            height: 90vw
            margin: 5vw auto 0 auto

    .Une-image-vaut-mille-mots
        position: relative
        display: flex
        flex-direction: column-reverse
        img
            width: 140vw
            pointer-events: none
            margin: -20vw 0 0 0
        .motif1
            position: absolute
            width: 20vw
            right: 0
            bottom: -10vw
        .container
            right: 12vw
            top: 15vw
            h4
                font-family: "avenir"
                text-align: center
                margin: 0
                font-size: 5vw
                color: #5E6282
                margin-top: 10vw
            h2
                font-family: "helvetica"
                font-size: 7vw
                font-weight: bold
                margin: 0
                color: $primary
                text-align: center
            p
                font-family: "avenir"
                font-size: 3.5vw
                margin-bottom: 3vw
                width: 90vw
                margin: 7vw auto 0 auto
                text-align: center
            .compatible
                position: absolute
                bottom: -10vw
                margin-left: 5vw
            img
                position: absolute
                bottom: -23vw
                margin-top: 1vw
                margin-left: 5vw
                width: 90vw

    .Une-Gestion-Plus-Efficace-Des-Commandes
        position: relative
        display: flex
        flex-direction: column
        justify-content: space-between
        margin-top: 35vw
        .motif2
            position: absolute
            width: 20vw
            left: 0
            bottom: -20vw
        .container
            width: 90vw
            margin-left: 5vw
            margin-top: 8vw
            h2
                font-family: "helvetica"
                font-size: 7vw
                font-weight: bold
                margin: 0 0 10vw 0
                color: $primary
                text-align: center
            p
                font-family: "avenir"
                font-size: 3.5vw
                margin-bottom: 3vw
                width: 90vw
                text-align: center
                position: absolute
                bottom: -38vw
            .row
                p
                    position: relative
                    color: #1C1E52
                    font-family: "avenir heavy"
                    margin: 0.5vw auto 0 auto
                    align-items: center
                    font-size: 4vw
                    bottom: unset
                    margin: 5vw 0 0 0 
                    &::before
                        position: absolute
                        content: ""
                        display: block
                        width: 6vw
                        height: 6vw
                        left: 12vw
                        top: -1vw
                        background-image: url("./../../images/like.png")
                        background-size: cover
                        background-repeat: no-repeat


        img
            width: 90vw
            margin: 10vw auto 0 auto 

    .form
        position: relative
        background: #FFFFFF
        border: 1px solid #EAECF0
        box-shadow: 0px 24px 48px -12px rgba(17, 24, 39, 0.25)
        border-radius: 1vw
        width: 90vw
        margin: 50vw auto 10vw auto 
        img
            position: absolute
            width: 10vw
            height: 10vw
            top: -5vw
            right: -5vw
        h2
            font-family: "Helvetica"
            font-size: 6vw
            font-weight: bold
            color: $primary
            text-align: center
        p
            font-family: "avenir"
            font-size: 4.5vw
            width: 80vw
            text-align: center
            margin: 0 auto
        .submit
            font-family: 'Poppin'
            font-style: normal
            width: 80vw
            height: 8vw
            background-color: #6697E6
            border: none
            border-radius: 2vw
            color: #fff
            font-size: 4vw
            margin: 10vw 0 5vw 5vw
            &:hover
                cursor: pointer
                background-color: #f1f1f1
        .form-group
            display: flex
            flex-direction: column
            width: 80vw
            margin: 9vw auto 0 auto
            justify-content: space-between
            flex-wrap: wrap
            .field
                position: relative
                width: 74vw
                display: flex
                flex-direction: column
                font-family: "avenir heavy"
                p
                    font-weight: bold
                    font-size: 2.5vw
                    margin: 0
                    position: absolute
                    color: red
                    bottom: -5vw
                    left: 2vw
                    text-align: left
                label
                    font-size: 4vw
                    font-weight: bold
                    margin: 5vw 0 .8vw 0
                input
                    font-family: arial
                    width: 100%
                    border: none
                    border-radius: 0.2vw
                    height: 10vw
                    border: 0.1vw solid #cccccc
                    padding: 0 3vw
                    font-size: 3vw
                .select__value-container
                    font-family: arial
                    font-weight: normal
                    font-size: 3vw
                .select__control
                    width: 80vw
                    height: 5vw
                    border: 0.1vw solid #cccccc
                .select__single-value
                    margin-top: -1.5vw
                .select__indicator-separator, .select__dropdown-indicator
                    margin-top: -1.5vw