@font-face 
    font-family: "Poppin"
    src: url("./../fonts/Poppins-Regular.ttf")

@font-face 
    font-family: "Poppin bold"
    src: url("./../fonts/Poppins-Bold.ttf")

@font-face 
    font-family: "ConcertOne"
    src: url("./../fonts/ConcertOne-Regular.ttf")

@font-face 
    font-family: "dmsans-bold"
    src: url("./../fonts/DMSans-Bold.ttf")

@font-face 
    font-family: "dmsans"
    src: url("./../fonts/DMSans-Regular.ttf")

@font-face 
    font-family: "avenir"
    src: url("./../fonts/Avenir Medium.ttf")

@font-face 
    font-family: "avenir heavy"
    src: url("./../fonts/Avenir Heavy.ttf")