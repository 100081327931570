@import "./../fonts.sass"
@import "./../theme.sass"
.bm-burger-button 
  position: fixed
  width: 36px
  height: 30px
  left: 36px
  top: 36px


.bm-burger-bars 
  background: $primary


/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover 
  background: #a90000


/* Position and sizing of clickable cross button */
.bm-cross-button 
  height: 24px
  width: 24px


/* Color/shape of close button cross */
.bm-cross 
  background: $primary


.bm-menu-wrap 
  position: fixed
  height: 100%
  width: 70vw !important


/* General sidebar styles */
.bm-menu 
  background: #ffffff
  padding: 2.5em 1.5em 0
  font-size: 1.15em


/* Morph shape necessary with bubble or elastic */
.bm-morph-shape 
  fill: #373a47


/* Wrapper for item list */
.bm-item-list 
  color: #b8b7ad


/* Individual item */
.bm-item 
  display: inline-block


/* Styling of overlay */
.bm-overlay 
  background: rgba(0, 0, 0, 0.3)

.logomenu
    margin: auto
    width: 18vw
    height: 20vw
    padding-bottom: 10vw
    margin-left: 2vw

.menu-items
    color: $primary
    font-family: "Poppin bold"
    font-size: 4.5vw
    margin-top: 8vw
.actif-menu
.menu-item
    position: relative
    display: flex
    height: 12vw
    width: 55vw
    align-items: center
    margin: auto
    padding: 0 0 0 5vw
    border-radius: 1vw
    margin-top: 8vw
    &:hover
        cursor: pointer
    span
        margin-left: 5vw
    svg
        width: 8vw
        height: 8vw         